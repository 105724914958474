<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.certificados.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :sem-botao-ok="true"
      :sem-filtro="!buscarPermissao('Certificado', 'Listar')"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="colunasTabelas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      :mostrar-seletor="false"
      sem-selecionar-todos
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Editar')"
            :disabled="!podeEditar(slotProps.data)"
            @click="confirmacaoAbrirCalibracao(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Visualizar')"
            @click="visualizarCalibracao(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.visualizar_calibracao') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Visualizar')"
            @click="abrirCertificado(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.visualizar_certificado') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Assinar')"
            :disabled="!podeAssinar(slotProps.data)"
            :tooltip="tooltipAssinar(slotProps.data)"
            @click="assinarCertificado(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.assinar_certificado') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Cancelar')"
            :disabled="!podeCancelar(slotProps.data)"
            @click="cancelarCalibracao(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.cancelar_certificado') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'CancelarESubstituir')"
            :disabled="!podeCancelarSubstituir(slotProps.data)"
            @click="cancelarSubstituirCalibracao(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.cancelar_substituir') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'DefinirDerivas')"
            :disabled="!podeDefinirDerivas(slotProps.data)"
            @click="irParaDefinirDerivas(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.definir_derivas') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'AvaliarCertificado')"
            :disabled="!podeAvaliar(slotProps.data)"
            @click="avaliarCertificado(slotProps.data)"
          >
            {{ $t('modulos.certificados.botoes.avaliar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Certificado', 'Visualizar')"
            :disabled="slotProps.data.situacaoCertificado != 'Assinado'"
            @click="abrirGraficoTendecia(slotProps.data)"
          >
            {{ $t('modulos.instrumento.botoes.grafico_tendencia') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:situacaoCertificado="{ slotProps }">
        <div
          :class="`${
            formatarSituacaoCertificado(slotProps.data.situacaoCertificado)?.cor
          }--text`"
        >
          {{
            formatarSituacaoCertificado(slotProps.data.situacaoCertificado)
              ?.text
          }}
        </div>
      </template>

      <template v-slot:situacaoAvaliacao="{ slotProps }">
        <div
          :class="`${
            formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.cor
          }--text`"
        >
          {{
            formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.text
          }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina';
import CertificadoService from '@common/services/cadastros/CertificadoService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.certificados.tabela.codigo'),
          },
          {
            value: 'dataCalibracao',
            text: this.$t('modulos.certificados.tabela.data_calibracao'),
            width: 150,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            value: 'codigoInstrumento',
            text: this.$t('modulos.certificados.tabela.codigo_instrumento'),
            width: 200,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.certificados.tabela.tipo_instrumento'),
            width: 240,
          },
          {
            value: 'ordemServico.codigo',
            text: this.$t('modulos.certificados.tabela.ordem_servico'),
            width: 200,
          },
          {
            value: 'cliente.nome',
            text: this.$t('modulos.certificados.tabela.cliente'),
            width: 240,
          },
          {
            value: 'tipoCalibracao.nome',
            text: this.$t('modulos.certificados.tabela.tipo_calibracao'),
            width: 200,
          },
          {
            value: 'situacaoCertificado',
            text: this.$t('modulos.certificados.tabela.situacao_certificado'),
            width: 200,
          },
          {
            value: 'situacaoAvaliacao',
            text: this.$t('modulos.certificados.tabela.situacao_avaliacao'),
            width: 200,
          },

          {
            value: 'usuarioSignatario.nome',
            text: this.$t('modulos.certificados.tabela.usuario_signatario'),
            width: 240,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    colunasTabelas: function () {
      const colunas = [
        {
          value: 'codigo',
          text: this.$t('modulos.certificados.tabela.codigo'),
        },
        {
          value: 'dataCalibracao',
          text: this.$t('modulos.certificados.tabela.data_calibracao'),
          width: 150,
          formatter: (v) => {
            if (v) return helpers.formatarDataBr(v);
          },
        },
        {
          value: 'codigoInstrumento',
          text: this.$t('modulos.certificados.tabela.codigo_instrumento'),
          width: 200,
        },
        {
          value: 'tipoInstrumento.nome',
          text: this.$t('modulos.certificados.tabela.tipo_instrumento'),
          width: 240,
        },
        {
          value: 'ordemServico.codigo',
          text: this.$t('modulos.certificados.tabela.ordem_servico'),
          width: 200,
        },
        {
          value: 'cliente.nome',
          text: this.$t('modulos.certificados.tabela.cliente'),
          width: 240,
        },
        {
          value: 'tipoCalibracao.nome',
          text: this.$t('modulos.certificados.tabela.tipo_calibracao'),
          width: 200,
        },
        {
          value: 'situacaoCertificado',
          text: this.$t('modulos.certificados.tabela.situacao_certificado'),
          width: 200,
        },
        {
          value: 'situacaoAvaliacao',
          text: this.$t('modulos.certificados.tabela.situacao_avaliacao'),
          width: 200,
        },

        {
          value: 'usuarioSignatario.nome',
          text: this.$t('modulos.certificados.tabela.usuario_signatario'),
          width: 240,
        },
        {
          value: 'flagLiberadoCliente',
          text: this.$t('modulos.certificado_terceiro.tabela.liberarClientes'),
          formatter: (v) => {
            return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
          },
        },
      ];
      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.pop();
      return [...colunas];
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Certificado', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificados.titulos.listagem')
    );
  },

  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    abrirGraficoTendecia: function (certificado) {
      this.$router.push({
        name: 'instrumento-certificado-grafico-tendencia',
        params: {
          instrumentoId: certificado.instrumentoId,
          certificadoId: certificado.id,
        },
      });
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formatarSituacaoAvaliacao: function (v) {
      return helpers.SituacaoAvaliacaoEnum.find((el) => el.value == v);
    },
    formatarSituacaoCertificado: function (v) {
      return helpers.SituacaoCertificadoEnum.find((el) => el.value == v);
    },
    podeEditar: function (item) {
      return (
        item.situacaoCertificado == 'EmAndamento' &&
        item.ordemServicoInstrumento != null
      );
    },
    podeVisualizar: function (item) {
      return item.ordemServicoInstrumento != null;
    },
    podeAssinar: function (item) {
      return (
        item.situacaoCertificado == 'PendenteAssinatura' && item.flagAssinar
      );
    },
    podeCancelar: function (item) {
      return (
        item.flagCancelar &&
        !item.flagLiberadoCliente &&
        !item.certificadoSubstituido?.id
      );
    },
    podeCancelarSubstituir: function (item) {
      return (
        item.situacaoCertificado != 'Cancelado' && item.flagLiberadoCliente
      );
    },
    podeDefinirDerivas: function (item) {
      return (
        item.instrumentoFlagPadrao &&
        item.situacaoCertificado === 'Assinado' &&
        (item.situacaoAvaliacao === 'NaoIniciada' ||
          item.situacaoAvaliacao === 'PendenteDerivas' ||
          item.situacaoAvaliacao === 'PendenteAvaliacao')
      );
    },
    podeAvaliar: function (item) {
      return (
        item.situacaoAvaliacao != 'NaoIniciada' &&
        item.situacaoAvaliacao != 'PendenteDerivas'
      );
    },
    irParaDefinirDerivas: function (item) {
      this.$router.push({
        name: 'certificado-definir-derivas',
        params: {
          calibracaoId: item.id,
          instrumentoId: item.instrumentoId,
        },
      });
    },
    tooltipAssinar: function (item) {
      if (!item.flagAssinar) return this.$t('modulos.certificados.tooltip');
      return '';
    },
    confirmacaoAbrirCalibracao(item) {
      if (item.ordemServicoInstrumento.situacao == 'EmExecucaoCalibracao') {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.operacao.confirmacao_calibracao')
        ).then(() => {
          this.abrirCalibracao(
            item.ordemServico.id,
            item.instrumentoId
          );
        });
      } else {
        this.abrirCalibracao(
          item.ordemServico.id,
          item.instrumentoId
        );
      }
    },
    visualizarCalibracao: function (item) {
      this.$router.push({
        name: 'calibracao-visualizar',
        params: {
          ordemServicoId: item.ordemServicoId,
          instrumentoId: item.ordemServicoInstrumento.id,
          calibracaoId: item.id,
        },
        query: {
          retorno: 'certificado',
        },
      });
    },
    abrirCalibracao: function (ordemId, instrumentoId) {
      this.$router.push({
        name: 'calibracao-editar',
        params: {
          ordemServicoId: ordemId,
          instrumentoId: instrumentoId,
        },
        query: {
          retorno: 'certificado',
        },
      });
    },
    assinarCertificado: function (item) {
      this.$router.push({
        name: 'calibracao-certificado',
        params: { id: item.id },
        query: { assinar: true },
      });
    },
    cancelarCalibracao: function (item) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.confirmar_cancelar')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.cancelarCalibracao(item.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.certificados.cancelado_sucesso`)
            );
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    cancelarSubstituirCalibracao: function (item) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.cancelar_substituir')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.cancelarCalibracao(item.id, true)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.certificados.cancelado_sucesso`)
            );
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    avaliarCertificado: function (item) {
      this.$router.push({
        name: 'certificado-avaliacao',
        params: { calibracaoId: item.id },
      });
    },
    abrirCertificado: function (item) {
      this.$router.push({
        name: 'calibracao-certificado',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
  },
};
</script>
